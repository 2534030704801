import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function Dropdown({sub,lang}) {
    const [dropdown,setDropdown] = useState(false);

  return (
    <>
    { lang ?
          <ul className={dropdown ? 'hover-nav clicked' : 'hover-nav' } onClick={() => setDropdown(!dropdown) }>
          <div className='bottomnav-div'>
          { sub.map(subnav => {
              return ( <li key={subnav.subid}>
                  <NavLink onClick={() => setDropdown(false)} to={subnav.path} className='Nav-item'>{subnav.name}</NavLink>
              </li>)
          }) }
          </div>
      </ul>
      :
        <ul className={dropdown ? 'hover-nav clicked' : 'hover-nav' } onClick={() => setDropdown(!dropdown) }>
        <div className='bottomnav-div'>
        { sub.map(subnav => {
            return ( <li key={subnav.subid}>
                <NavLink onClick={() => setDropdown(false)} to={`/en${subnav.path}`} className='Nav-item fontNoto'>{subnav.name_eng}</NavLink>
            </li>)
        }) }
        </div>
      </ul>
    }
    </>
  )
}
