import React, {useState, useEffect} from "react";
import { Carousel } from "react-responsive-carousel";
import "./Carousel.css";
import CarouselPage from "./CarouselPage";
import "react-responsive-carousel/lib/styles/carousel.min.css";



export default function Carouseli ({pages,lang})  {

  return <Carousel autoPlay={true} stopOnHover={false} infiniteLoop={true} showStatus={false} interval={3500}
  showThumbs={false}>
    
        { 
          pages.map((page) =>{ if(lang) 
            return <CarouselPage lang={lang} key={page.id} id={page.url_title} date={page.date} name={page.title} text={page.header} button_link={page.header} image={page.photo} /> 
            else 
            return <CarouselPage lang={lang} key={page.id} id={page.url_title} date={page.date} name={page.title_en} text={page.header_en} button_link={page.header} image={page.photo} /> 
          })

        }
      
 </Carousel>
}
