import { useState,useEffect } from 'react';
import './Banks.css';
import Arrowright from '../img/arrowright.svg';

export default function Partners({lang}) {
    const [IntData, setIntData] = useState([])
    const [LocData, setLocData] = useState([])
    const [Toggle, setToggle] = useState(false)

    let getbanks = async () => {
        let response = await fetch('/api/about/partners/')
        let data = await response.json()
        setIntData(data.int_partners)
        setLocData(data.loc_partners)
      }
    
      useEffect(() => {
        getbanks()
      },[])

    

    function BankCard({bank}){ 

        const [ShowBank,SetShowBank] = useState(false);

            return (
                <>
                  <div className='bank-card' onClick={() => SetShowBank(!ShowBank) }>
                      <img src={bank.logo}></img>
                      <h2>{bank.name}</h2>
                  </div>
                  <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                  className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                     <div className='bank-modal'>
                        <img src={bank.logo}></img>
                        <h2>{bank.name}</h2>
                     </div>
                  </div>
                </>
            )
    }

  return (
        <>
        {  lang ?
             <>
             <div className='flex-start margintopstart'>
              <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  პარტნიორები </span> 
              </div>
              <div className='home-hr'></div>
              <div className='flex-start'>
              <span className='other-news-home margintop1p5'>საერთაშორისო თანამშრომლობა</span> 
              </div>
              <div className='banks-grid'>
              {
                  IntData.map(bank => {
                      return <BankCard key={bank.id} bank={bank} />})
              }
              </div>
              <div className='flex-start'>
              <span className='other-news-home margintop1p5'>ადგილობრივი თანამშრომლობა</span> 
              </div>
              <div className='banks-grid'>
              {
                  LocData.map(bank => {
                      return <BankCard key={bank.id} bank={bank} />})
              }
              </div>
              
            </>
            :
            <>
            <div className='flex-start margintopstart'>
             <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Partners </span> 
             </div>
             <div className='home-hr'></div>
             <div className='flex-start'>
             <span className='other-news-home margintop1p5 fontNoto'>International Associacion</span> 
             </div>
             <div className='banks-grid'>
             {
                 IntData.map(bank => {
                     return <BankCard key={bank.id} bank={bank} />})
             }
             </div>
             <div className='flex-start'>
             <span className='other-news-home margintop1p5 fontNoto'>Local Associacion</span> 
             </div>
             <div className='banks-grid'>
             {
                 LocData.map(bank => {
                     return <BankCard key={bank.id} bank={bank} />})
             }
             </div>
             
           </>

        }
        </>
           )
}
