import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link, useParams } from 'react-router-dom';
import './myCalendar.css';
import './News.css';
import Arrowright from '../img/arrowright.svg';
import HomeCard from '../components/HomeCard';
import { Helmet } from "react-helmet-async";


export default function News({lang}) {
  const [value, onChange] = useState(new Date());
  const [Data, setData] = useState([])
  const [Toggle, setToggle] = useState(false)
  const [translatecat,settranslatecat] = useState('')
  const [descr,setDescr] = useState('')


  let params = useParams();

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
       return false;
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
       return false;
    }
    return true;
 };

    const months = ["იანვარი","თებერვალი","მარტი","აპრილი","მაისი","ივნისი","ივლისი","აგვისტო","სექტემბერი","ოქტომბერი","ნოემბერი","დეკემბერი"]
    const days = ["კვი","ორშ","სამ","ოთხ","ხუთ","პარ","შაბ"]
  

  let getarticles = async () => {
    
    let  requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
              'category': params.id,
              'curr_month': String(value.getMonth() + 1).padStart(1, ''),
              'curr_year': value.getFullYear()
         })
    };
    let response = await fetch('/api/blog/articles/', requestOptions)
    let data = await response.json()
    setToggle(true)
    setData(data.articles)
    return data['article']

  }

  function translatecategory(params){
       if (lang){
         if(params.id == 'news') {
         settranslatecat('სიახლეები')
         setDescr('არ გამოტოვო სიახლეები, ანალიტიკა და ღონისძიებები საქართველოს საბანკო ასოციაციისგან.')
         }
         else if (params.id == 'events') {
         settranslatecat('ღონისძიებები')
         setDescr('საქართველოს საბანკო ასოციაცია წარმოგიდგენთ ინფორმაციას ჩანუშნული და ჩატარებული ღონისძიებების შესახებ.')
         }
         else if (params.id == 'analytics') {
         settranslatecat('ანალიტიკა')
         setDescr('საქართველოს საბანკო ასოციაცია წარმოგიდგენთ ყოველთვიურ საბანკო სექტორის თვის შეჯამებას.')
         }
       }
       else {
         settranslatecat(params.id)
         if(params.id == 'news') {
          setDescr("Don't miss news, analytics and events from the Banking Association of Georgia.")
          }
          else if (params.id == 'events') {
          setDescr('Banking Association of Georgia presents information about planned and conducted events.')
          }
          else if (params.id == 'analytics') {
          setDescr('Banking Association of Georgia presents the monthly summary of the banking sector month.')
          }
       }
  }

  useEffect(() => {
    getarticles();
    translatecategory(params);
  },[params])

  useEffect(() => {
    translatecategory(params);
  },[lang])

  const Maincard = ({Data}) => {  
    var card = Data.filter(card => Date.parse(card.date) == Date.parse(value))[0]
    if(card !== undefined){
      return <HomeCard lang={lang} key={card} Card={card} />
    }
    else if(Data.length > 0){
      return <HomeCard  lang={lang} key={Data[0]} Card={Data[0]} />
    }
    
  }
  

  const OtherArticles = ({Data}) => Data.slice(0,4).map(card => {
      return <OtherArticle  key={card.id} Article={card}/>
    }
  )

  const OtherArticlesMobile = ({Data}) => Data.slice(0,2).map(card => {
    return <OtherArticle key={card.id} Article={card}/>
  }
)

  const Homecards = ({Data}) => Data.map(card => {

    return <HomeCard  lang={lang} key={card.id} Card={card} />
  })

  function OtherArticle({Article}){
    return(
      <>
        {  lang ?
            <div className='other-article'>
                <img src={Article.photo}></img>
                <div className='other-article-content'>
                <Link className='underlinenone' to={`/article/${Article.url_title}`}>
                  <h2>{Article.title}</h2>
                  </Link>
                  <span>{Article.date}</span>
                </div>
            </div>
            :
              <div className='other-article'>
              <img src={Article.photo}></img>
              <div className='other-article-content'>
              <Link className='underlinenone' to={`/en/article/${Article.url_title}`}>
                  <h2>{Article.title_en}</h2>
                  </Link>
                  <span>{Article.date}</span>
              </div>
          </div>
        }
        </>
       )
  }


  return (

    <>  
            	{
                lang ? 
                <Helmet>
                <title>{translatecat} - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content={descr} data-react-helmet="true" />
                <meta property="og:title" content={`${translatecat} - Banking Association of Georgia`} data-react-helmet="true"/>
                <meta property="og:description" content={descr} data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content={`https://www.association.ge/${params.id}`} data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content={`${translatecat} - Banking Association of Georgia`}data-react-helmet="true"/>
                <meta name="twitter:description" content={descr} data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href={`https://www.association.ge/${params.id}`} data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>{translatecat} - Banking Association of Georgia</title>
                <meta name="description" content={descr} data-react-helmet="true" />
                <meta property="og:title" content={`${translatecat} - Banking Association of Georgia`} data-react-helmet="true"/>
                <meta property="og:description" content={descr} data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content={`https://www.association.ge/en/${params.id}`} data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content={`${translatecat} - Banking Association of Georgia`} data-react-helmet="true"/>
                <meta name="twitter:description" content={descr} data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href={`https://www.association.ge/en/${params.id}`} data-react-helmet="true"/>
              </Helmet>
              }
    { lang ? 
       ( !deviceType() ? 
         //mobile
         <>
          <div className='flex-start margintopstart'>
         <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>მთავარი</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
         </div>
         <div className='home-hr'></div>
         <div className='main-layout'>
           < Maincard Data={Data} />
          <div className='other-articles'>
                       <OtherArticlesMobile Data={Data} />
                 </div>
         {/* < Calendar locale="ka" onChange={onChange}  value={value}/> */}
         </div>
         <div className='flex-start'>
         <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>მთავარი</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
         </div>
         <div className='home-hr'></div>
         <div className='home-grid'>
              <Homecards Data={Data} />
         </div>
         </>
         :
         //desktop
         <>
           <div className='flex-start margintopstart'>
         <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>მთავარი</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
         </div>
         <div className='home-hr'></div>
         <div className='main-layout'>
           < Maincard Data={Data} />
          <div className='other-articles'>
                       <OtherArticles Data={Data} />
                 </div>
         < Calendar formatMonth={(locale,date) => {return months[date.getMonth()]}}
         formatShortWeekday={(locale,date) => {return days[date.getDay()]}}
         formatMonthYear={(locale,date) => {return months[date.getMonth()]+' '+date.getFullYear()}} onChange={onChange}  value={value}/>
         </div>
         <div className='flex-start'>
         <span className='other-news-home margintop1p5'></span> 
         </div>
         <div className='home-hr'></div>
         <div className='home-grid'>
              <Homecards Data={Data} />
         </div>
         </>
      )
      :
      ( !deviceType() ? 
     //mobile
     <>
      <div className='flex-start margintopstart'>
     <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en/'>Home</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
     </div>
     <div className='home-hr'></div>
     <div className='main-layout'>
       < Maincard Data={Data} />
      <div className='other-articles'>
                   <OtherArticlesMobile Data={Data} />
             </div>
     {/* < Calendar locale="ka" onChange={onChange}  value={value}/> */}
     </div>
     <div className='flex-start'>
     <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en/'>Home</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
     </div>
     <div className='home-hr'></div>
     <div className='home-grid'>
          <Homecards Data={Data} />
     </div>
     </>
     :
     //desktop
     <>
       <div className='flex-start margintopstart'>
     <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en/'>Home</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  {translatecat} </span> 
     </div>
     <div className='home-hr'></div>
     <div className='main-layout'>
       < Maincard Data={Data} />
      <div className='other-articles'>
                   <OtherArticles Data={Data} />
             </div>
     < Calendar locale="ka" onChange={onChange}  value={value}/>
     </div>
     <div className='flex-start'>
     <span className='other-news-home margintop1p5'></span> 
     </div>
     <div className='home-hr'></div>
     <div className='home-grid'>
          <Homecards Data={Data} />
     </div>
     </>
      )

    }
    
 </>
  )
}
