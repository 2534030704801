import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Home.css';

export default function ({committee,lang}) {
  return (
     <>
      { lang ?
          <div className='Committeecard-div'>
              <Link className="underlinenone" to={`/about-us/committee/${committee.url_title}`}> 
              <img src={committee.picture}></img>
              </Link>
              <Link className="underlinenone" to={`/about-us/committee/${committee.url_title}`}> 
              <h2>{committee.name}</h2>
              </Link>
              <p>{committee.header}</p>
          </div>
          :
          <div className='Committeecard-div'>
            <Link className="underlinenone" to={`/en/about-us/committee/${committee.url_title}`}> 
            <img src={committee.picture}></img>
            </Link>
            <Link className="underlinenone" to={`/en/about-us/committee/${committee.url_title}`}> 
            <h2>{committee.name_en}</h2>
            </Link>
            <p>{committee.header_en}</p>
         </div>
      }
     </>
        
  )
}
