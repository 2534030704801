import {useState, useEffect} from 'react';
import Carouseli from '../components/Carouseli';
import './Home.css';
import Data1 from '../Data.js';
import HomeCard from '../components/HomeCard';
import Arrowright from '../img/arrowright.svg';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import WhiteLoading from '../components/WhiteLoading';

export default function Home({lang}) {

	//states
	const [Data, setData] = useState(Data1)
	const [Toggle, setToggle] = useState(true)
	//

	function renderimg(index) {
		let bannerdata = (Data?.Banners).concat((Data?.Banners).concat(Data?.Banners))
		if(index == 0) {
			return <a target='_blank' href={bannerdata[0].url}><img className='green-bar-img' src={bannerdata[0].photo}></img></a>
		}
		else if(index == 1) {
			return <a target='_blank' href={bannerdata[1].url}><img className='green-bar-img' src={bannerdata[1].photo}></img></a>
		}
		else {
			return <a target='_blank' href={bannerdata[2].url}><img className='green-bar-img' src={bannerdata[2].photo}></img></a>
		}
	}

	//Data Fetch
	const getData = async () => {
		let response = await fetch('/api/blog/articles_for_home/')
		let data = await response.json()
		setData(data)
		setToggle(false)
	}
	
	useEffect(() => {
		getData() 
	},[])
	useEffect(() => {
		getData() 
	},[lang])
	//

	if ( Toggle ) {
		return  <WhiteLoading/>
	}

	//Category Loop
	const Homecards = Data?.categories.map((section,index) => {
		return (
		     <>  
		    {  lang ? 
			 <>
			 <div key={index} className='flex-start margintopstart'>
					 <span className='other-news-home margintop1p5'>{section.name_ge} <img src={Arrowright}></img></span>
					 </div>
					 <div className='home-hr'>
					 </div>
					 <div className='home-grid'>
							 { 
								 section.articles?.map(card => {
									 return <HomeCard lang={lang} key={card.id} Card={card} />
								 } )
								
							 }
					 </div>
					 <div className='home-hr'>
					 </div>
					 <div className='flex-end'>
						 <Link className='underlinenone' to={`/${section.name}`}>  <span className='other-news-home'>სხვა {section.name_ge} <img src={Arrowright}></img></span></Link>
					 </div>
					 <div className='green-bar'>
						{ 
                            renderimg(index) 
					    }
			 		</div>
			 </>
			 :
			 <>
			<div className='flex-start margintopstart'>
					<span className='other-news-home margintop1p5'>{section.name} <img src={Arrowright}></img></span>
					</div>
					<div className='home-hr'>
					</div>
					<div className='home-grid'>
							{ 
								section.articles?.map(card => {
									return <HomeCard lang={lang} key={card.id} Card={card} />
								} )
							
							}
					</div>
					<div className='home-hr'>
					</div>
					<div className='flex-end'>
						<Link className='underlinenone' to={`/en/${section.name}`}>  <span className='other-news-home'>Other {section.name} <img src={Arrowright}></img></span></Link>
					</div>
					<div className='green-bar'>
						{ 
                            renderimg(index) 
					    }
		 		    </div>
			</>

			}
		</>
		)
	})
	//

	const Schema = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"name": "საქართველოს საბანკო ასოციაცია",
		"alternateName": "Georgian Banking Association",
		"url": "https://bankingassociation.ge",
		"logo": "https://bankingassociation.ge/static/media/Logo.6c95ac359be4d2af1f9054942723c8cc.svg",
		"contactPoint": {
		  "@type": "ContactPoint",
		  "telephone": "+995 322 054 142",
		  "contactType": "customer service",
		  "areaServed": "GE",
		  "availableLanguage": ["en","Georgian"]
		},
		"sameAs": [
		  "https://www.facebook.com/bankingassociationofgeorgia/",
		  "https://twitter.com/banking_georgia/",
		  "https://www.instagram.com/bankingassociation/",
		  "https://www.linkedin.com/company/banking-association-of-georgia/"
		]
	  }

	//Page render
	return (    
					<>
					 {
						lang ? 
						<Helmet>
							<script type="application/ld+json">
								{`
									{
									"@context": "https://schema.org",
									"@type": "Organization",
									"name": "საქართველოს საბანკო ასოციაცია",
									"alternateName": "Georgian Banking Association",
									"url": "https://bankingassociation.ge",
									"logo": "https://bankingassociation.ge/static/media/Logo.6c95ac359be4d2af1f9054942723c8cc.svg",
									"contactPoint": {
									"@type": "ContactPoint",
									"telephone": "+995 322 054 142",
									"contactType": "customer service",
									"areaServed": "GE",
									"availableLanguage": ["en","Georgian"]
									},
									"sameAs": [
									"https://www.facebook.com/bankingassociationofgeorgia/",
									"https://twitter.com/banking_georgia/",
									"https://www.instagram.com/bankingassociation/",
									"https://www.linkedin.com/company/banking-association-of-georgia/"
									]
									}
									`}
							</script>
							<title>საქართველოს საბანკო ასოციაცია</title>
							<meta name="description" content="საქართველოს საბანკო ასოციაცია წარმოადგენს არასამეწარმეო (არაკომერციულ) იურიდიულ პირს, რომელიც აერთიანებს საქართელოში მოქმედ საბანკო და საფინანსო-საკრედიტო საქმიანობით დაკავებულ იურიდიულ პირებს." data-react-helmet="true" />
							<meta property="og:title" content="საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
							<meta property="og:description" content="საქართველოს საბანკო ასოციაცია წარმოადგენს არასამეწარმეო (არაკომერციულ) იურიდიულ პირს, რომელიც აერთიანებს საქართელოში მოქმედ საბანკო და საფინანსო-საკრედიტო საქმიანობით დაკავებულ იურიდიულ პირებს." data-react-helmet="true"/>
							<meta property="og:type" content="website" data-react-helmet="true" />
							<meta property="og:site_name" content="association" data-react-helmet="true"/>
							<meta property="og:url" content="https://www.association.ge/" data-react-helmet="true" />
							<meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
							<meta name="twitter:title" content="საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
							<meta name="twitter:description" content="საქართველოს საბანკო ასოციაცია წარმოადგენს არასამეწარმეო (არაკომერციულ) იურიდიულ პირს, რომელიც აერთიანებს საქართელოში მოქმედ საბანკო და საფინანსო-საკრედიტო საქმიანობით დაკავებულ იურიდიულ პირებს." data-react-helmet="true"/>
							<meta name="twitter:image" content="" data-react-helmet="true"/>
							<meta name="twitter:image:alt" content="" data-react-helmet="true"/>
							<meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
							<link rel="canonical" href="https://www.association.ge/" data-react-helmet="true"/>
							{/* <script src="https://lab.omoi.digital/script-react.js"></script> */}
						</Helmet>
						:
						<Helmet>
							<script type="application/ld+json">
								{`
									{
									"@context": "https://schema.org",
									"@type": "Organization",
									"name": "საქართველოს საბანკო ასოციაცია",
									"alternateName": "Georgian Banking Association",
									"url": "https://bankingassociation.ge",
									"logo": "https://bankingassociation.ge/static/media/Logo.6c95ac359be4d2af1f9054942723c8cc.svg",
									"contactPoint": {
									"@type": "ContactPoint",
									"telephone": "+995 322 054 142",
									"contactType": "customer service",
									"areaServed": "GE",
									"availableLanguage": ["en","Georgian"]
									},
									"sameAs": [
									"https://www.facebook.com/bankingassociationofgeorgia/",
									"https://twitter.com/banking_georgia/",
									"https://www.instagram.com/bankingassociation/",
									"https://www.linkedin.com/company/banking-association-of-georgia/"
									]
									}
									`}
							</script>
							<title>Banking Association of Georgia</title>
							<meta name="description" content="Banking Association of Georgia is a non-commercial legal entity that unites legal entities engaged in banking and financial-credit activities operating in Georgia." data-react-helmet="true" />
							<meta property="og:title" content="Banking Association of Georgia" data-react-helmet="true"/>
							<meta property="og:description" content="Banking Association of Georgia is a non-commercial legal entity that unites legal entities engaged in banking and financial-credit activities operating in Georgia." data-react-helmet="true"/>
							<meta property="og:type" content="website" data-react-helmet="true" />
							<meta property="og:site_name" content="association" data-react-helmet="true"/>
							<meta property="og:url" content="https://www.association.ge/" data-react-helmet="true" />
							<meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
							<meta name="twitter:title" content="Banking Association of Georgia" data-react-helmet="true"/>
							<meta name="twitter:description" content="Banking Association of Georgia is a non-commercial legal entity that unites legal entities engaged in banking and financial-credit activities operating in Georgia." data-react-helmet="true"/>
							<meta name="twitter:image" content="" data-react-helmet="true"/>
							<meta name="twitter:image:alt" content="" data-react-helmet="true"/>
							<meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
							<link rel="canonical" href="https://www.association.ge/" data-react-helmet="true"/>
							{/* <script src="https://lab.omoi.digital/script-react.js"></script> */}
				     </Helmet>
					 }
					        
							<Carouseli lang={lang} pages={Data?.carousel} />
							{Homecards}
				  </>
				
	)
	//
}
