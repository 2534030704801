import React,{useState, useEffect} from 'react'
import { Link, useParams} from 'react-router-dom';
import Data from '../Data';
import './Article.css';
import Arrowright from '../img/arrowright.svg';
import Article from './Article';

export default function CommitteeOpened({lang}) {
   const [Data,setData] = useState([])
   const [Opened,setOppend] = useState([])

   let params = useParams();

      let getdata = async () => {
         let  requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                  'url_title': params.id   
            })
      };
         let response = await fetch('/api/about/committee/', requestOptions)
         let data = await response.json()
         setData(data.committees)
         setOppend(data.committee)
      }

      useEffect(() => {
         getdata()
      },[params])
      const Card = ({Opened}) => {
            return <GetArticle Article={Opened}/>
         }
      

      const OtherArticles = ({Data}) => Data.map((card,index) => {
            return <OtherArticle key={index} Article={card}/>
      })
   

      function GetArticle({Article}){
         return(
               <>
               { lang ?
                  <>
                     <img src={Article.photo}></img>
                     <h2>{Article.title}</h2>
                     <p>{Article.header}</p>
                     <p dangerouslySetInnerHTML={{ __html: Article.body }} ></p>
                  </>
                  :
                  <>
                     <img src={Article.photo}></img>
                     <h2>{Article.title_en}</h2>
                     <p>{Article.header_en}</p>
                     <p dangerouslySetInnerHTML={{ __html: Article.body_en }} ></p>
                  </>
               }
            
               </>
         )
      }

      function OtherArticle({Article}){
         return(
            <>
            { lang ?
               <div className='other-article'>
                  <img src={Article.picture}></img>
                  <div className='other-article-content'>
                  <Link className='underlinenone' to={`/about-us/committee/${Article.url_title}`}>
                  <h2>{Article.name}</h2>
                  </Link>
                  </div>
            </div>
            :
            <div className='other-article'>
               <img src={Article.picture}></img>
               <div className='other-article-content'>
               <Link className='underlinenone' to={`/en/about-us/committee/${Article.url_title}`}>
               <h2>{Article.name_en}</h2>
               </Link>
               </div>
            </div>
            }
            </>
         
            
         )
      }



       return (
          <>
           { lang ?
            
          <>
               <div className='flex-start margintopstart'>
             <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;   <Link className='underlinenone' to='/about-us/committee'>კომიტეტები</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  <span className='mobile-link-tag'>{Opened.title}</span> </span> 
              
            </div>
            <div className='home-hr'>
            </div>
            <div className='article-flex-between'>
               <div className='article-left'>
                  <Card Opened={Opened} />
               </div>
               <div className='article-right'>
                     <span className='other-news'>კომიტეტები <img src={Arrowright}></img></span>
                     <div className='other-articles'>
                           <OtherArticles Data={Data} />
                     </div>
               </div>         
            </div>
            <div className='flex-start'>
               <span className='other-news-home margintop1p5'>წევრები:</span>
               
            </div>
            <div className='home-hr'>
               </div>
               <div className='Team-list'>
            {
               Opened.members ?
                  Opened.members.map((member,index) => {
                     return   <div key={index} className='Team-list-member'>
                                 <h3 className='other-news-home'>{member.full_name}</h3>
                                 <h4 className=''>{member.job_title}</h4>
                              </div>
                  })
               :
               <span className='other-news-home margintop1p5' >წევრები არ არიან</span>
            }
            </div>
         
            </>
             :
               <>
               <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  <Link className='underlinenone' to='/en/about-us/committee'>committees</Link> <img src={Arrowright}></img> &nbsp;&nbsp;  <span className='mobile-link-tag'>{Opened.title_en}</span></span> 
            </div>
               <div className='home-hr'>
               </div>
               <div className='article-flex-between'>
                  <div className='article-left'>
                     <Card Opened={Opened} />
                  </div>
                  <div className='article-right'>
                        <span className='other-news'>committees <img src={Arrowright}></img></span>
                        <div className='other-articles'>
                              <OtherArticles Data={Data} />
                        </div>
                  </div>         
               </div>
               <div className='flex-start'>
                  <span className='other-news-home margintop1p5'>Members:</span>
                  
               </div>
               <div className='home-hr'>
                  </div>
                  <div className='Team-list'>
               {
                  Opened.members ?
                     Opened.members.map((member,index) => {
                        return   <div key={index} className='Team-list-member'>
                                    <h3 className='other-news-home'>{member.full_name_en}</h3>
                                    <h4 className=''>{member.job_title_en}</h4>
                                 </div>
                     })
                  :
                  <span className='other-news-home margintop1p5' >No Members</span>
               }
               </div>
            
               </>
            }
     
      
      </>
   )
   }
