import {useState, useEffect} from 'react';
import Teamtop from '../img/teamtop.png';
import Teamdata from '../Teamdata';
import Arrowright from '../img/arrowright.svg';
import { Helmet } from 'react-helmet-async';

export default function Mission({lang}) {
  const [Data, setData] = useState([])
  

  let getData = async () => {
      let response = await fetch('/api/about/mission/')
      let data = await response.json()
      
      setData(data.governance)
  }

  useEffect(() => {
      getData()   
  },[])

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
       return false;
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
       return false;
    }
    return true;
 };

  return (
     
        <>   
        {
                lang ? 
                <Helmet>
                <title>ჩვენი მისია - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაციის მისია" data-react-helmet="true" />
                <meta property="og:title" content="ჩვენი მისია - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაციის მისია" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/about-us/mission" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="ჩვენი მისია - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაციის მისია" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/about-us/mission" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Our Mission - Banking Association of Georgia</title>
                <meta name="description" content="The mission of the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Our Mission - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="The mission of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/about-us/mission" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Our Mission - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="The mission of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/about-us/mission" data-react-helmet="true"/>
              </Helmet>
              }
        { lang ?
                (  deviceType() ? 
                //desktop
                <>
                <div className='flex-start margintopstart'>
                  <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მისია </span> 
                  </div>
                  <div className='home-hr'></div>
              <div className='team-top'>
                  <div className='team-top-left'>
                      <label>ჩვენი მისია</label>
                      <p>{Data.header}</p>
                  </div>
                  <img alt='man-writing' src={Data.picture}></img>
              </div>
              <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
              </>
            :
            //mobile
              <>
              <div className='flex-start margintopstart'>
                <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მისია </span> 
                </div>
                <div className='home-hr'></div>
            <div className='team-top'>
                    <label>ჩვენი მისია</label>
                    <img alt='man-writing' src={Data.picture}></img>
                    <p className='team-top-leftp'>{Data.header}</p>
            </div>
            <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
            </>
          )
       :
          (  deviceType() ? 
          //desktop
            <>
            <div className='flex-start margintopstart'>
              <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Mission </span> 
              </div>
              <div className='home-hr'></div>
          <div className='team-top'>
              <div className='team-top-left'>
                  <label>Our Mission</label>
                  <p>{Data.header_en}</p>
              </div>
              <img alt='man-writing' src={Data.picture}></img>
          </div>
          <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
          </>
        :
        //mobile
          <>
          <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Mission </span> 
            </div>
            <div className='home-hr'></div>
        <div className='team-top'>
                <label>Our Mission</label>
                <img alt='man-writing' src={Data.picture}></img>
                <p className='team-top-leftp'>{Data.header_en}</p>
        </div>
        <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
        </>
      )
          
        }
      
        </>
  )
}
