import { BrowserRouter as Router,Routes,Route,Link } from "react-router-dom";
import React from 'react';
import './Carousel.css';

export default function CarouselPage({text,name,image,date,id,lang}) {

  return (
        
       <>
        {lang ?
            <div key={id} className="car-div">
        
                <div className="carousel-content">
                <Link className="underlinenone" to={`/article/${id}`}> 
                    <h2 className="carousel-header">{name}</h2> 
                    </Link>
                    <span className="carousel-date">{date}</span>
                    <p dangerouslySetInnerHTML={{ __html: text }}  className="carousel-text"></p>
                </div>
              
                <Link className="underlinenone" to={`/article/${id}`}> 
                   <div className="carousel-img-div">

                       <img className="carousel-img" alt="" src={image} />

                       <div className="carousel-img-header">

                             <h2 className="fonttitlegeo">{name}</h2>

                       </div>

                   </div>
                </Link>
            </div>
               
               :

               <div key={id} className="car-div">
        
               <div className="carousel-content">
               <Link className="underlinenone" to={`/en/article/${id}`}> 
                   <h2 className="carousel-header fontNoto">{name}</h2> 
                   </Link>
                   <span className="carousel-date fontNotoDate">{date}</span>
                   <p dangerouslySetInnerHTML={{ __html: text }}  className="carousel-text fontNotoDate"></p>
               </div>
             
               <Link className="underlinenone" to={`/en/article/${id}`}> 
                  <div className="carousel-img-div">

                      <img className="carousel-img" alt="" src={image} />

                      <div className="carousel-img-header">

                            <h2 className="fontNotoDate">{name}</h2>

                      </div>

                  </div>
               </Link>
                </div>
            }
       </>
  )
}
