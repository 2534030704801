import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Home.css';

export default function ({Card,lang}) {
  return (
          <>
          { lang ?
              <div className='homecard-div'>
                <Link className="underlinenone" to={`/article/${Card.url_title}`}> 
                <img src={Card.photo}></img>
                </Link>
                <Link className="underlinenone" to={`/article/${Card.url_title}`}> 
                <h2>{Card.title}</h2>
                </Link>
                <span>{Card.date}</span>
                <p dangerouslySetInnerHTML={{ __html: Card.header }} ></p>
               </div>
                  :
              <div className='homecard-div'>
                <Link className="underlinenone" to={`/en/article/${Card.url_title}`}> 
                <img src={Card.photo}></img>
                </Link>
                <Link className="underlinenone" to={`/en/article/${Card.url_title}`}> 
                <h2 className='fontNoto'>{Card.title_en}</h2>
                </Link>
                <span className='fontNotDate'>{Card.date}</span>
                <p className='fontNotoDate' style={{fontWeight:'400'}} dangerouslySetInnerHTML={{ __html: Card.header_en }} ></p>
              </div>
          }
          </>
     
  )
}
