import React, { useState, useEffect } from 'react';
import Arrowright from '../img/arrowright.svg';
import pdfimg from '../img/pdf.svg';
import nopdf from '../img/nopdf.svg';
import './Finances.css'
import { Helmet } from 'react-helmet-async';

export default function Finances({lang}) {

  const [years,SetYears] = useState([2021,2020,2019,2018,2017,2016])
  const [Data, setData] = useState([])
  const [Toggle, setToggle] = useState(true)

  let getData = async () => {
    
    
    let response = await fetch('/api/members/financial_data')
    let data = await response.json()
    setToggle(false)
    setData(data.data)
    return data['data']
  }

  useEffect(() => {
    getData()
  },[])

      function incrementyear(years){
          const newyears = years.map(years => years+1)
          SetYears(newyears);
      }

      function decrementyear(years){
        const oldyears = years.map(years => years-1)
        SetYears(oldyears);
    }

     

    function BankData({Data}){
      return(
        <div className='bankdata-card-flex'>
        <div className='bankdata-card'>
            <img src={Data.photo}></img>
            {
              lang ?
              <h2>{Data.bank}</h2>
              :
              <h2>{Data.bank_en}</h2>
            }
        </div>
        <div className='pdf-flex'>
                {
                  years.map(year => {
                       var found = Data.financial_data.find(yr => (yr.document_year == year));
                       if( found != undefined ){
                         return (
                          <a target="_blank" key={found.id} href={found.document}><img src={pdfimg}></img></a>
                         )
                       }
                       else{
                        return (
                         <img src={nopdf}></img>
                         )
                       }   
                  })
                }
            </div>   
       </div>
      )
    }

    function BankDataMobile({Data}){
      return(
        <div className='bankdata-card-flex'>
        <div className='bankdata-card'>
            <img src={Data.photo}></img>
            {
              lang ?
              <h2>{Data.bank}</h2>
              :
              <h2>{Data.bank_en}</h2>
            }
        </div>
        <div className='pdf-flex'>
                {
                  years.slice(0,2).map(year => {
                       var found = Data.financial_data.find(yr => (yr.document_year == year));
                       if( found != undefined ){
                         return (
                          <a target="_blank" key={found.id} href={found.document}><img src={pdfimg}></img></a>
                         )
                       }
                       else{
                        return (
                         <img src={nopdf}></img>
                         )
                       }   
                  })
                }
            </div>   
       </div>
      )
    }

    const Financialdatas = ({Data}) => 
        Data.map((bank,index) => {
          return <BankData key={index} Data={bank} />
        })

        const FinancialdatasMobile = ({Data}) => 
        Data.map((bank,index) => {
          return <BankDataMobile key={index} Data={bank} />
        })
    

  const yearlist =  years.map(year => 
    {
        return(
        <label key={year} className='year-label'>{year}</label>
        )
    }
    )

    const yearlistMobile =  years.slice(0,2).map(year => 
      {
          return(
          <label key={year} className='year-label'>{year}</label>
          )
      }
      )

    const deviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
         return false;
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
         return false;
      }
      return true;
   };


  return (

    <>
    {
                lang ? 
                <Helmet>
                <title>ფინანსური მონაცემები - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაცია. ფინანსური მონაცემები." data-react-helmet="true" />
                <meta property="og:title" content="ფინანსური მონაცემები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაცია. ფინანსური მონაცემები." data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/members/financial-data" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="ფინანსური მონაცემები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაცია. ფინანსური მონაცემები." data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/members/financial-data" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Financial Data - Banking Association of Georgia</title>
                <meta name="description" content="Banking Association of Georgia. Financial data." data-react-helmet="true" />
                <meta property="og:title" content="Financial Data - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Banking Association of Georgia. Financial data." data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/members/financial-data" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Financial Data - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Banking Association of Georgia. Financial data." data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/members/financial-data" data-react-helmet="true"/>
              </Helmet>
              }
     { !deviceType() ? 
     //mobile
     <>
      <div className='flex-start margintopstart'>
        { lang ?
           <span className='other-news-home margintop1p5'>წევრები  <img src={Arrowright}></img> &nbsp;&nbsp;  ფინანსური მონაცემები </span>
           :
           <span className='other-news-home margintop1p5 fontNoto'>Members  <img src={Arrowright}></img> &nbsp;&nbsp;  Financial Data </span>
        }
                </div>
        <div className='home-hr'></div>
        <div className='flex-end'>
            <div className='years-flex'>
                <div className='year-btns'>
                <button className={`${years[0] != 2021 ? 'btn-years' : 'btn-years-disabled' }`} onClick={() => incrementyear(years)}>&lt;</button>
                <button className={`${years[1] != 2016 ? 'btn-years' : 'btn-years-disabled' }`} onClick={() => decrementyear(years)}>&gt;</button>
                </div>
                {yearlistMobile}
               
            </div>
        </div>
        <div className='bankdata-div'>
             <FinancialdatasMobile Data={Data}/>
        </div>
        </>
        :
        //desktop
        <>
        <div className='flex-start margintopstart'>
        { lang ?
           <span className='other-news-home margintop1p5'>წევრები  <img src={Arrowright}></img> &nbsp;&nbsp;  ფინანსური მონაცემები </span>
           :
           <span className='other-news-home margintop1p5 fontNoto'>Members  <img src={Arrowright}></img> &nbsp;&nbsp;  Financial Data </span>
        }
        
           </div>
           <div className='home-hr'></div>
           <div className='flex-end'>
               <div className='years-flex'>
                   {yearlist}
                   <div className='year-btns'>
                   <button className={`${years[0] != 2021 ? 'btn-years' : 'btn-years-disabled' }`} onClick={() => incrementyear(years)}>&lt;</button>
                   <button className={`${years[5] != 2016 ? 'btn-years' : 'btn-years-disabled' }`} onClick={() => decrementyear(years)}>&gt;</button>
                   </div>
               </div>
           </div>
           <div className='bankdata-div'>
                <Financialdatas Data={Data}/>
           </div>
           </>
      }
    </>
  )
}
