import React, {useEffect,useState} from 'react';
import {
    Link,
    useLocation
  } from "react-router-dom";

export default function Search({lang}) {
    let [Data, setData]= useState([]);
    let [Toggle, setToggle] = useState(false);

    function useQuery() {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }

    let query = useQuery();
    let keyword = query.get("keyword")
    
    let getData = async (keyword) => {
      let  requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
                'keyword':keyword
           })
      };
      let response = await fetch('/api/blog/search/',requestOptions )
      let data = await response.json()
  
      setData(data['articles'])
      setToggle(true)
  
    }
    useEffect(() => {
        getData(keyword);
    },[keyword])
  

  return (
       
      <>
      { Data.length > 0 ?
         <>
        <div className='margintopstart'></div>
      { 
        Data.map(result => {
            return (
              <>
              {
                lang ? 
                <div className='text-pdf-flex'>
                    <div className='text-pdf-col'>
                       <Link className='underlinenone' to={`/article/${result.url_title}`}>
                       <h1 className='text-pdf-header'>{result.title}</h1>
                       </Link>
                        <span className='text-pdf-date'>{result.date}</span>
                        <p className='text-pdf-p'>{result.header}</p>
                    </div>
                </div>
                :
                <div className='text-pdf-flex'>
                    <div className='text-pdf-col'>
                       <Link className='underlinenone' to={`/en/article/${result.url_title}`}>
                       <h1 className='text-pdf-header'>{result.title_en}</h1>
                       </Link>
                        <span className='text-pdf-date'>{result.date}</span>
                        <p className='text-pdf-p'>{result.header_en}</p>
                    </div>
                </div>
              }
              </>
            )
        }

        )
       }
       </>
       :
        <>{ lang ?
          <>
          <div className='margintopstart'></div>
            <div className='fonttitlegeo'>
                   ვერ მოიძებნა
            </div>
          </>
          :
          <>
          <div className='margintopstart'></div>
            <div className='fontNoto'>
                  Not Found
            </div>
          </>
          }
        
       </>
      }
      </>
  )
}
