import React, { useState } from 'react';
import './Footer.css';
import Fb from '../img/Fb.svg';
import Instagram from '../img/Instagram.svg';
import Linkedin from '../img/Linkedin.svg';
import Twitter from '../img/Twitter.svg';
import { Link } from 'react-router-dom';

export default function ({lang}) {

      const [ Email , SetEmail ] = useState()

      const date = new Date()

      const subscribe = async () => {
            let  requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                        'email': Email  
                   })
              };
            if ( /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email) ) {
                    const res = await fetch('/api/members/subscribe/',requestOptions)
                    const data = res.json()
                    if ( res.status == 200 ) {
                        if ( lang ) {
                              SetEmail('თქვენ წარმატებით გამოიწერეთ ჩვენი საინფორმაციო ბილეტენი')
                        } else {
                              SetEmail('you have successifully subscribed to our newsletter')
                        }
                    }
            } else {
                  if ( lang ) {
                        SetEmail('გთხოვთ სწორად შეიყვანოთ ელ.ფოსტა')
                  } else {
                        SetEmail('please input email correctly')
                  }
            }
      }

  return (
        <>
        {  lang ?
           <div className='footer'>
           <div className='footer-sub'>
                  <h4 className='biuleteni'>გამოიწერეთ ჩვენი საინფორმაციო ბიულეტენი!</h4>
                  <div className='flex-biuleteni' style={{marginBottom:'2.5vw'}}>
                       <input onChange={(e) => SetEmail(e.target.value)} value={Email} placeholder='შეიყვანეთ თქვენი ელ.ფოტის მისამართი' className='input-subscribe'></input>
                       <button onClick={() => subscribe()} className='btn-subscribe'>გამოწერა</button>
                  </div>
           </div>
            <div className='top-footer'>
                   <div className='footer-contact-div'>
                         <div className='socials-grid'>
                               <a target='_blank' href='https://www.facebook.com/bankingassociationofgeorgia/'><img src={Fb}></img></a>
                               <a target='_blank' href='https://www.instagram.com/bankingassociation/?utm_source=ig_profile_share&igshid=1vp2dwrk6t1cx'><img src={Instagram}></img></a>
                               <a target='_blank' href='https://www.linkedin.com/company/banking-association-of-georgia/'><img src={Linkedin}></img></a>
                               <a target='_blank' href='https://twitter.com/banking_georgia'><img src={Twitter}></img></a>
                         </div>
                         <div className='footer-text-div'>
                               <a target='_blank' href='https://www.google.com/maps/place/Grato+Passage/@41.7001446,44.7841451,14z/data=!4m5!3m4!1s0x40440cd0d63103a5:0x14910ff0529fe2e0!8m2!3d41.7082256!4d44.786142'>მერაბ კოსტავას ქუჩა 37/39, გრატო, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                8 სართული, 0179, თბილისი, საქართველო
                               </a>
                               <span>
                              <a href="tel:+995 322 054 142">+995 322 054 142</a>  <a href="mailto:info@association.ge">info@association.ge</a>  
                               </span>
                         </div>
                   </div>
            </div>
            <div className='bottom-footer'>
                       <span>ყველა უფლება დაცულია © {date.getFullYear()} </span>
            </div>
         
          </div>
          :
          <div className='footer'>
         <div className='footer-sub'>
                <h4 className='biuleteni fontNoto'>Subscribe to our Newsletter</h4>
                <div className='flex-biuleteni' style={{marginBottom:'2.5vw'}}>
                     <input onChange={(e) => SetEmail(e.target.value)} value={Email} placeholder='Enter your E-mail' className='input-subscribe fontNoto'></input>
                     <button onClick={() => subscribe()} className='btn-subscribe fontNoto'>Subscribe</button>
                </div>
         </div>
          <div className='top-footer'>
                 <div className='footer-contact-div'>
                       <div className='socials-grid'>
                             <a target='_blank' href='https://www.facebook.com/bankingassociationofgeorgia/'><img src={Fb}></img></a>
                             <a target='_blank' href='https://www.instagram.com/bankingassociation/?utm_source=ig_profile_share&igshid=1vp2dwrk6t1cx'><img src={Instagram}></img></a>
                             <a target='_blank' href='https://www.linkedin.com/company/banking-association-of-georgia/'><img src={Linkedin}></img></a>
                             <a target='_blank' href='https://twitter.com/banking_georgia'><img src={Twitter}></img></a>
                       </div>
                       <div className='footer-text-div'>
                             <a className='fontNoto' style={{width:'inherit'}} target='_blank' href='https://www.google.com/maps/place/Grato+Passage/@41.7001446,44.7841451,14z/data=!4m5!3m4!1s0x40440cd0d63103a5:0x14910ff0529fe2e0!8m2!3d41.7082256!4d44.786142'>Merab Kostava str 37/39, Grato,  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              8 floor, 0179, Tbilisi, georgia
                             </a>
                             <span>
                            <a className='fontNoto' href="tel:+995 322 054 142">+995 322 054 142</a>  <a className='fontNoto' href="mailto:info@association.ge">info@association.ge</a>  
                             </span>
                       </div>
                 </div>
          </div>
          <div className='bottom-footer'>
                     <span className='fontNoto'>All rights reserved © {date.getFullYear()} </span>
          </div>
       
    </div>
        }
        </>
  )
}
