import React, { useState , useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import MyGoogleMap from '../components/MyGoogleMap';
import Arrowright from '../img/arrowright.svg';

export default function Contact({lang}) {

  const [send,setSend] = useState(false);
  const first_name = useRef()
  const last_name = useRef()
  const message = useRef()
  const sender = useRef()
  const [ X , SetX ] = useState('')

    const [stepCount, setStepCount] = useState(0)
    const [permissionState, setPermissionState] = useState(false);

//     useEffect(() => {

// 		if ('DeviceMotionEvent' in window) {
// 			if ( typeof(DeviceMotionEvent) !== 'undefined' 
// 			  && typeof(DeviceMotionEvent.requestPermission) === 'function') {
// 				DeviceMotionEvent.requestPermission().then(permission => {
// 					if (permission === 'granted') {
// 						setPermissionState(true)
// 						window.addEventListener('devicemotion', handleOrientation);
// 					} else {
// 						setPermissionState(false)
// 					}
// 				})
// 			} else {
// 				setPermissionState(false);
// 			}
// 		} else {
// 		    setPermissionState(false);
// 		}

// 		if (permissionState == true) {
// 			window.addEventListener('devicemotion', handleOrientation);
// 		}

// 		return () => {
// 			window.removeEventListener('devicemotion', handleOrientation);
// 		}
// 	  }, []);

// 	  function handleClick() {
//             DeviceMotionEvent.requestPermission().then(permission => {
// 				if (permission === 'granted') {
// 					setPermissionState(true)
// 					window.addEventListener('devicemotion', handleOrientation);
// 				} else {
// 					setPermissionState(false)
// 				}
// 			}).catch(() => {
// 				alert('not supported')
// 			})
// 	  }
	
//         let stepCounter = 0;
//         let threshold = 4.1; 
//         let currentVectorLength = 0;
//         let previousEWMA = 0;
//         let alpha = 0.1;
//         let x = 0
//         let y = 0
//         let z = 0
//         let previousTimestamp = 0;
//         let stepInterval = 700;

// 	  function handleOrientation(event) {
//             let currentTimestamp = Date.now();
//             if ((currentTimestamp - previousTimestamp) > stepInterval) {
//                   x = event.acceleration.x;
//                   y = event.acceleration.y;
//                   z = event.acceleration.z;
//                   let vector = Math.sqrt(x*x+y*y+z*z)
//                   currentVectorLength = vector;
//                   // Calculate the new EWMA value
//                   let newEWMA = (alpha * currentVectorLength) + ((1 - alpha) * previousEWMA);
//                   // Update the previous EWMA value
//                   previousEWMA = newEWMA;
//                   // Use an algorithm to detect when a step has been taken
//                   if (newEWMA > threshold) {
//                         stepCounter++;
//                         setStepCount(stepCounter)
//                   }
//                   previousTimestamp = currentTimestamp
//             }
//             SetX(currentVectorLength).toFixed(3)
// 	  }

  let sendContactEmail = async () => {
    
      let  requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
                'first_name': first_name.current.value,   
                'last_name': last_name.current.value,  
                'message': message.current.value,   
                'send_to': sender.current.value,   
           })
      };
      let response = await fetch('/api/about/sendemail/',requestOptions )
      if ( response.status == 200 ) {
            setSend(true)
      }
  
    }

  useEffect(() => {
     setTimeout(() => setSend(false),5000) 
  }, [send])
  
  const deviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
         return false;
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
         return false;
      }
      return true;
   };

  return (

      <>
       {
            lang ? 
                <Helmet>
                <title>კონტაქტი - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="დაუკავშირდით საქართველოს საბანკო ასოციაციას" data-react-helmet="true" />
                <meta property="og:title" content="კონტაქტი - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="დაუკავშირდით საქართველოს საბანკო ასოციაციას" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/about-us/committee" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="კონტაქტი - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="დაუკავშირდით საქართველოს საბანკო ასოციაციას" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/about-us/committee" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Contact - Banking Association of Georgia</title>
                <meta name="description" content="Contact the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Contact - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Contact the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/about-us/committee" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Contact - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Contact the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/about-us/committee" data-react-helmet="true"/>
              </Helmet>
              }
      { lang ? 
         (  deviceType() ? 
         //desktop
               <>
           <div className='flex-start margintopstart'>
             <span className='other-news-home margintop1p5'>კონტაქტი</span> 
             </div>
             <div className='home-hr'></div>
             <div className='contact-between'>
                  <div className={`${!send ? 'form-side' : 'none'}`}>
                     <div className='contact-form-flex'>
                           <label className='contact-label'>სახელი</label>
                           <input ref={first_name} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label'>გვარი</label>
                           <input ref={last_name} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label'>ელ.ფოსტა</label>
                           <input ref={sender} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label'>შეტყობინება</label>
                           <textarea ref={message} className='contact-textarea'></textarea>
                     </div>
                     <div className='flex-send-button'>
                           <button onClick={() => sendContactEmail()} className='btn-send'>გაგზავნა</button>
                     </div>
                   
                  </div>
                      <div className={`${send ? 'form-side' : 'none'}`}>
                            <div className='window-sent'>
                               თქვენი შეტყობინება 
                               წარმატებით გაიგზავნა!
                            </div>
                      </div>
                  <div className='google-div'>
                          <MyGoogleMap/>
                  </div>
             </div>
             </>
             :
             //mobile
             <>
               {/* { !permissionState && 
                  <button style={{marginTop:'24vw'}} onClick={handleClick}>
                        Request Permission
                  </button>
            }
            <p style={{marginTop:'10vw'}}>Permission State: {permissionState.toString()}</p>
            <p>X: {X}</p>
            <p>Steps taken: {stepCount}</p> */}
             <div className='flex-start margintopstart'>
               <span className='other-news-home margintop1p5'>კონტაქტი</span> 
               </div>
               <div className='home-hr'></div>
               <div className='contact-between'>
                    <div className={`${!send ? 'form-side' : 'none'}`}>
                       <div className='contact-form-flex'>
                             <label className='contact-label'>სახელი</label>
                             <input ref={first_name} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label'>გვარი</label>
                             <input ref={last_name} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label'>ელ.ფოსტა</label>
                             <input ref={sender} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label'>შეტყობინება</label>
                             <textarea ref={message} className='contact-textarea'></textarea>
                       </div>
                       <div className='flex-send-button'>
                             <button onClick={() => sendContactEmail()} className='btn-send'>გაგზავნა</button>
                       </div>
                     
                    </div>
                        <div className={`${send ? 'form-side' : 'none'}`}>
                              <div className='window-sent'>
                                 თქვენი შეტყობინება 
                                 წარმატებით გაიგზავნა!
                              </div>
                        </div>
                    <div className='google-div'>
                            <MyGoogleMap/>
                    </div>
               </div>
               </>
         )
         :
         (  deviceType() ? 
         //desktop
               <>
           <div className='flex-start margintopstart'>
             <span className='other-news-home margintop1p5 fontNoto'>Contact</span> 
             </div>
             <div className='home-hr'></div>
             <div className='contact-between'>
                  <div className={`${!send ? 'form-side' : 'none'}`}>
                     <div className='contact-form-flex'>
                           <label className='contact-label fontNoto'>Firstname</label>
                           <input ref={first_name} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label fontNoto'>Lastname</label>
                           <input ref={last_name} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label fontNoto'>E-mail</label>
                           <input ref={sender} className='contact-input'></input>
                     </div>
                     <div className='contact-form-flex'>
                           <label className='contact-label fontNoto'>Message</label>
                           <textarea ref={message} className='contact-textarea'></textarea>
                     </div>
                     <div className='flex-send-button'>
                           <button onClick={() => sendContactEmail()} className='btn-send fontNoto'>SEND</button>
                     </div>
                   
                  </div>
                      <div className={`${send ? 'form-side' : 'none'}`}>
                            <div className='window-sent fontNoto'>
                               your message was sent successfully!
                            </div>
                      </div>
                  <div className='google-div'>
                          <MyGoogleMap/>
                  </div>
             </div>
             </>
             :
             //mobile
             <>
             <div className='flex-start margintopstart'>
               <span className='other-news-home margintop1p5 fontNoto'>Contact</span> 
               </div>
               <div className='home-hr'></div>
               <div className='contact-between'>
                    <div className={`${!send ? 'form-side' : 'none'}`}>
                       <div className='contact-form-flex'>
                             <label className='contact-label fontNoto'>Firstname</label>
                             <input ref={first_name} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label fontNoto'>Lastname</label>
                             <input ref={last_name} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label fontNoto'>E-mail</label>
                             <input ref={sender} className='contact-input'></input>
                       </div>
                       <div className='contact-form-flex'>
                             <label className='contact-label fontNoto'>Message</label>
                             <textarea ref={message} className='contact-textarea'></textarea>
                       </div>
                       <div className='flex-send-button'>
                             <button onClick={() => sendContactEmail()} className='btn-send fontNoto'>SEND</button>
                       </div>
                     
                    </div>
                        <div className={`${send ? 'form-side' : 'none'}`}>
                              <div className='window-sent fontNoto'>
                              your message was sent successfully!
                              </div>
                        </div>
                    <div className='google-div'>
                            <MyGoogleMap/>
                    </div>
               </div>
               </>
         )

      }
      </>
    
   
  )
}
