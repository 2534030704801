import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import { Fragment, useState ,useEffect } from 'react';
import Footer from './components/Footer';
import Article from './pages/Article';
import Team from './pages/Team';
import News from './pages/News';
import NewsOpened from './pages/NewsOpened';
import ProjectOpened from './pages/ProjectOpened';
import Banks from './pages/Banks';
import MainProjects from './pages/MainProjects';
import Committees from './pages/Committees';
import CommitteeOpened from './pages/CommitteeOpened';
import Mission from './pages/Mission';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import Finances from './pages/Finances';
import Standarts from './pages/Standarts';
import Search from './pages/Search';
import Error404 from './pages/Error404';
import { Helmet } from 'react-helmet-async';

function App() {

  var WebFont = require('webfontloader');
  WebFont.load({
    custom: {
      families: ['BPG Nino Mkhedruli','BPG Nino Mtavruli','Noto Sans Display'],
      urls: ['index.css']
    }
  });


 
  
   
  const [Lang,setLang] = useState(true);
  const LangFunction = (bool) => {
      if( bool == true ){
        setLang(true)
      }
      else{
        setLang(false)
      }
  }  

      useEffect(() => {
        if ( window.location.pathname.includes('/en') ) 
         setLang(false)
       }, [])

      //  useEffect(() => {
      //     if(Lang) {
      //     if( window.location.pathname.includes('/en'))
      //     window.location.pathname = window.location.pathname.split('/en')[1]
      //   }
      //   else{
      //     window.location.pathname = '/en' + window.location.pathname
      //   }
      //  }, [window.location])

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
       return false;
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
       return false;
    }
    return true;
 };
   
  return (

    <BrowserRouter>
        <Helmet htmlAttributes={{ lang : Lang ? 'ka' : 'en' }}>
        </Helmet>
        <div className="main">
        <ScrollToTop/>
        <Fragment>
           <Navbar langfun={LangFunction} lang={Lang} />
        <Routes>
           <Route path='/' element={<Home lang={Lang} />} /> 
           <Route path='/en' element={<Home lang={Lang} />} /> 
           <Route path='/:id' element={<News  lang={Lang}/>} />  
           <Route path='/en/:id' element={<News  lang={Lang}/>} />  
           <Route path='/projects' element={<MainProjects lang={Lang} />} />  
           <Route path='/en/projects' element={<MainProjects lang={Lang} />} />  
           <Route path='/projects/:id' element={<ProjectOpened  lang={Lang}/>} />  
           <Route path='/en/projects/:id' element={<ProjectOpened  lang={Lang}/>} />  
           <Route path='/news/:id' element={< NewsOpened lang={Lang} />} />        
           <Route path='/article/:id' element={<Article lang={Lang} />}/>
           <Route path='/en/news/:id' element={< NewsOpened lang={Lang} />} />        
           <Route path='/en/article/:id' element={<Article lang={Lang} />}/>
           <Route path='/about-us/governance' element={<Team lang={Lang} />}/>
           <Route path='/about-us/committee' element={<Committees lang={Lang} />}/>
           <Route path='/about-us/committee/:id' element={< CommitteeOpened lang={Lang} />}/>
           <Route path='/about-us/mission' element={< Mission lang={Lang} />}/>
           <Route path='/about-us/:id' element={< News lang={Lang} />}/>
           <Route path='/about-us/partners' element={< Partners lang={Lang} />}/>
           <Route path='/members/banks' element={<Banks lang={Lang} />}/>
           <Route path='/members/financial-data' element={<Finances lang={Lang} />}/>
           <Route path='/contact' element={<Contact lang={Lang} />}/>
           <Route path='/standarts' element={< Standarts lang={Lang} />}/>
           <Route path='/search' element={< Search lang={Lang} />}/>

           <Route path='/en/about-us/governance' element={<Team lang={Lang} />}/>
           <Route path='/en/about-us/committee' element={<Committees lang={Lang} />}/>
           <Route path='/en/about-us/committee/:id' element={< CommitteeOpened lang={Lang} />}/>
           <Route path='/en/about-us/mission' element={< Mission lang={Lang} />}/>
           <Route path='/en/about-us/:id' element={< News lang={Lang} />}/>
           <Route path='/en/about-us/partners' element={< Partners lang={Lang} />}/>
           <Route path='/en/members/banks' element={<Banks lang={Lang} />}/>
           <Route path='/en/members/financial-data' element={<Finances lang={Lang} />}/>
           <Route path='/en/contact' element={<Contact lang={Lang} />}/>
           <Route path='/en/standarts' element={< Standarts lang={Lang} />}/>
           <Route path='/en/search' element={< Search lang={Lang} />}/>
           <Route path='/error404' element={< Error404 lang={Lang} />}/>
        </Routes> 
        </Fragment>
        <Footer lang={Lang}/>
       </div>
    </BrowserRouter>
    
  );
}

export default App;
