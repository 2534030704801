import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import '../pages/Contact.css'

export default function MyGoogleMap() {

  const { isLoaded } = useLoadScript({googleMapsApiKey:'AIzaSyBdMVvtoAoNTAhNDBuoEc7DUnlG3q8p1q0',})

  if(!isLoaded) return <div>Loading...</div>
  return  <Map/>
}


function Map(){
  return <GoogleMap zoom={14} center={{lat:41.70828014652074,lng:44.78618348391475}} mapContainerClassName="map-container">
    <Marker position={{lat:41.70828014652074,lng:44.78618348391475}} />
  </GoogleMap>
}
