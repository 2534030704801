import {useState, useEffect} from 'react'
import { Link, useParams} from 'react-router-dom';
import './Article.css';
import Arrowright from '../img/arrowright.svg';
import WhiteLoading from "../components/WhiteLoading";



export default function ProjectOpened({lang}) {
    const [Project, setProject] = useState([])
    const [Data, setData] = useState([])
    const [Toggle, setToggle] = useState(true)

    let params = useParams();

    let getData = async () => {
        let  requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                  'url_title': params.id,
             })
        };
        let response = await fetch('/api/blog/project/',requestOptions)
        let data = await response.json()
        setToggle(false)
        setData(data.projects)
        setProject(data.project)
        return data['projects']
    
      }
    
    useEffect(() => {
        getData()
    },[params])

    const Card = ({project}) => {
           return <GetArticle key={project.id} Article={project}/>
        }
 

    const OtherArticles = ({Data}) => Data.map(card => {
           return <OtherArticle key={card.id} Article={card}/>
    })
     

    function GetArticle({Article}){
         return(
             <>
             <img src={Article.photo}></img>
             <h2>{Article.title}</h2>
             <p dangerouslySetInnerHTML={{ __html: Article.header }} ></p>
             <p dangerouslySetInnerHTML={{ __html: Article.body }} ></p>
             </>
         )
     }

    function OtherArticle({Article}){
        return( <>
             { lang ?
                <div className='other-article'>
                    <img src={Article.photo}></img>
                    <div className='other-article-content'>
                    <Link className='underlinenone' to={`/projects/${Article.url_title}`}>
                    <h2>{Article.title}</h2>
                    </Link>
                   </div>
                 </div>
               :
               <div className='other-article'>
                    <img src={Article.photo}></img>
                    <div className='other-article-content'>
                    <Link className='underlinenone' to={`/en/projects/${Article.url_title}`}>
                    <h2>{Article.title_en}</h2>
                    </Link>
                     </div>
                </div>
             }
             </>
        )
    }



  return <>
    { lang ?
            ( Toggle ?
                <WhiteLoading />
            :
            <>
            <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/en/'>მთავარი</Link>  <img src={Arrowright}></img> &nbsp;  პროექტები </span> 
            </div>
            <div className='home-hr'></div>
        <div className='article-flex-between'>
            <div className='article-left'>
                <Card project={Project} />
            </div>
            <div className='article-right'>
                <span className='other-news'>სხვა პროექტები <img src={Arrowright}></img></span>
                <div className='other-articles'>
                    <OtherArticles Data={Data} />
                </div>
            </div>         
        </div>
        </>
        )
        :
        ( Toggle ?
            <WhiteLoading />
          :
          <>
          <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en/'>Home</Link>  <img src={Arrowright}></img> &nbsp;  Projects </span> 
          </div>
          <div className='home-hr'></div>
      <div className='article-flex-between'>
          <div className='article-left'>
              <Card project={Project} />
          </div>
          <div className='article-right'>
              <span className='other-news fontNoto'>Other Projects <img src={Arrowright}></img></span>
              <div className='other-articles'>
                  <OtherArticles Data={Data} />
              </div>
          </div>         
      </div>
      </>
      )
    }
     </>
  
}
