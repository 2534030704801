import React from 'react';
import Countdown from 'react-countdown';

export default function ({lang}) {

    const renderer = ({ seconds, completed }) => {
        if (completed) {
            window.location.href = 'http://localhost:3000/'
        } else {
          return <span className='countdown'><label>{seconds}</label></span>;
        }
      };


  return (
    <div className='content-404 margintopstart'>
         <span className='span-404'>404</span>
         <span className='not-fnd'>გვერდი არ მოიძებნა</span>
         <span className='timer-span'>როგორც ჩანს, გვერდი, რომელსაც თქვენ ეძებთ გადავიდა სხვა მისამართზე ან აღარ არსებობს. კიდევ ერთხელ შეამოწმეთ მისამართის სისწორე ან სცადეთ ძებნის ფუნქციის გამოყენება. თქვენ ავტომატურად გადამისამართდებით მთავარ გვერდზე < Countdown date={Date.now() + 10000}  renderer={renderer} /> წამში.</span>
    </div>
  )
}
