import React, { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CommitteeCard from '../components/CommitteeCard';
import Arrowright from '../img/arrowright.svg';

export default function Committees({lang}) {
  const [Data,setData] = useState([])

  let getdata = async () => {
    let response = await fetch('/api/about/committees/')
    let data = await response.json()
    setData(data.committees)
  }

  useEffect(() => {
    getdata()
  },[])

  const CommitteesList = ({Data}) => Data.map(committee => {
        return <CommitteeCard lang={lang} key={committee.id} committee={committee} />
  })


  return (
    <>
      {
                lang ? 
                <Helmet>
                <title>კომიტეტი - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაციის კომიტეტი" data-react-helmet="true" />
                <meta property="og:title" content="კომიტეტი - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაციის კომიტეტი" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/about-us/committee" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="კომიტეტი - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაციის კომიტეტი" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/about-us/committee" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Committee - Banking Association of Georgia</title>
                <meta name="description" content="Committee of the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Committee - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Committee of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/about-us/committee" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Committee - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Committee of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/about-us/committee" data-react-helmet="true"/>
              </Helmet>
              }
    { lang ? 
          <>
          <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  კომიტეტები </span> 
             </div>
          <div className='home-hr'>
             </div>
             <div className='Committee-list'>
                      <CommitteesList Data={Data} />
              </div>
         </>
         :
         <>
         <div className='flex-start margintopstart'>
         <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Committees </span> 
            </div>
         <div className='home-hr'>
            </div>
            <div className='Committee-list'>
                     <CommitteesList Data={Data} />
             </div>
        </>
    }
    </>
  
  )
}
