import {useState,useEffect} from 'react';
import './Home.css';
import Data from '../Data.js';
import HomeCard from '../components/HomeCard';
import Arrowright from '../img/arrowright.svg';
import { Link, NavLink } from 'react-router-dom';
import ProjectCard from '../components/ProjectCard';
import WhiteLoading from "../components/WhiteLoading";
import { Helmet } from 'react-helmet-async';


export default function MainProjects({lang}) {
  const [Data, setData] = useState([])
  const [Toggle, setToggle] = useState(true)

  let getData = async () => {
    
    
    let response = await fetch('/api/blog/projects/')
    let data = await response.json()
    setToggle(false)
    setData(data.projects)
    return data['projects']

  }

  useEffect(() => {
    getData()
  },[])

  const Homecards = ({Data}) => Data.map(card => {

    return <ProjectCard lang={lang} key={card.id} Card={card} />
  })

  return <>
          {
                lang ? 
                <Helmet>
                <title>პროექტები - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="წარმოგიდგენთ პროექტებს, რომელთაც საქართველოს საბანკო ასოციაცია ახორციელებს" data-react-helmet="true" />
                <meta property="og:title" content="პროექტები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="წარმოგიდგენთ პროექტებს, რომელთაც საქართველოს საბანკო ასოციაცია ახორციელებს" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/pojects" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="პროექტები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="წარმოგიდგენთ პროექტებს, რომელთაც საქართველოს საბანკო ასოციაცია ახორციელებს" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/projects" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>projects - Banking Association of Georgia</title>
                <meta name="description" content="We present the projects implemented by the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="projects - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="We present the projects implemented by the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/projects" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="projects - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="We present the projects implemented by the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/projects" data-react-helmet="true"/>
              </Helmet>
              }
        {  lang ? 
           (
            Toggle ?
            <WhiteLoading />
            :
            <>
          <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>მთავარი</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  პროექტები </span> 
          </div>
          <div className='home-hr'>
          </div>
          <div className='home-grid'>
               <Homecards Data={Data} />
          </div>
          </>
          )
          :
          (
            Toggle ?
            <WhiteLoading />
            :
            <>
          <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en/'>Home</Link>  <img src={Arrowright}></img> &nbsp;&nbsp;  Projects </span> 
          </div>
          <div className='home-hr'>
          </div>
          <div className='home-grid'>
               <Homecards Data={Data} />
          </div>
          </>
          )
        }
     </>
}
