import React, { useState , useEffect } from 'react';
import './Navbar.css';
import Logo from '../img/Logo.svg';
import LogoText from '../img/LogoText.svg';
import LogoTextEng from '../img/LogoTextEng.svg';
import Searchicon from '../img/searchicon.svg';
import { Link, NavLink, useLocation, useRoutes } from 'react-router-dom';
import {navdata} from '../Nav';
import Dropdown from './Dropdown';
import mobilenavmenu from '../img/mobilenavmenu.svg';
import Toggleplus from './Toggleplus';

export default function Navbar({lang,langfun}) {  

  const loc = useLocation().pathname
  
  
  //states
  const [dropdown,setDropdown] = useState(false);
  const [dropdown1,setDropdown1] = useState(false);
  const [dropdown2,setDropdown2] = useState(false);
  const [dropdown3,setDropdown3] = useState(false);
  const [dropdown4,setDropdown4] = useState(false);
  const [SearchDrop,setSearchDrop] = useState(false);
  const [MobileNav,setMobileNav] = useState(false);
  const [dropdownmb,setDropdownmb] = useState(false);
  const [dropdownmb1,setDropdownmb1] = useState(false);
  const [dropdownmb2,setDropdownmb2] = useState(false);
  const [dropdownmb3,setDropdownmb3] = useState(false);
  const [dropdownmb4,setDropdownmb4] = useState(false);
  const [inpValue,setSearchValue] = useState('');
  //

    // Mobile Desktop switch
    const deviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
         return false;
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
         return false;
      }
      return true;
     };
    //


   //navbar render
   return (
          <>
          { lang ? 
          ( deviceType() ? 
          <div className='nav'>
            <Link className='underlinenone' to='/'>
            <div className='nav-logo-div'>
                   <img src={Logo} className='nav-logo'></img>
                   <img src={LogoText} className='nav-logo-text'></img>
            </div>
            </Link>
            <ul className='nav-links'>
                {navdata.map(item => {
                  if(item.name == 'წევრები'){
                    return ( 
                      <li onClick={() => {setDropdown1(!dropdown1);setDropdown2(false);setDropdown3(false);setDropdown4(false);setDropdown(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <span className={dropdown1 ? 'hover link_active' : 'hover'}>{item.name}</span>
                           {dropdown1 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                  else if(item.name == 'ღია ბანკინგი'){
                    return ( 
                      <li 
                           key={item.id} className={item.cName}>
                           <a className='' target='_blank' href='https://openfinance.ge/'>{item.name}</a>
                      </li>
                     )

                  }
                  else if(item.name == 'ჩვენს შესახებ'){
                    return ( 
                      <li onClick={() => {setDropdown3(!dropdown3);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown(false);setSearchDrop(false);}}
                            key={item.id} className={item.cName}>
                           <span  className={dropdown3 ? 'hover link_active' : 'hover'}>{item.name}</span>
                           {dropdown3 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                  else if(item.name == 'კონტაქტი'){
                    return ( 
                      <li onClick={() => {setDropdown(false);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <NavLink className=''  to={item.path}>{item.name}</NavLink>
                           {dropdown4 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                    
                  }
                  else{
                    return ( 
                      <li onClick={() => {setDropdown(!dropdown);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <span className={dropdown ? 'hover link_active' : 'hover'} >{item.name}</span>
                           {dropdown && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                })}
            </ul>
            <div className='nav-lang'> 
              <img onClick={() => {setSearchDrop(!SearchDrop);setDropdown(false);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);}} className='search-icon' src={Searchicon}></img>
              <div className={ SearchDrop ? 'hover-nav' : 'none'}>
                 <input onKeyUp={e => {setSearchValue(e.target.value);if(e.key === 'Enter'){ window.location.assign('/search?keyword='+inpValue)}}} className='search-inp'></input>
                 { lang ?
                  <button onClick={() => window.location.assign('/search?keyword='+inpValue)} className='btn-search'>ძიება</button>
                  :
                  <button onClick={() => window.location.assign('/search?keyword='+inpValue)} className='btn-search fontNoto'>Search</button>
                 }
              </div>
              <div className='lang-hr'></div>
              <a 
                 onClick={() => langfun(!lang)} 
                 href={
                  loc?.includes('/en') ? 
                  loc?.split('/en')[1] 
                      :
                   '/en'+loc
                 }
             className='btn-lang'>{ !lang ? 'ქარ' : 'ENG'}</a>
            </div>
           </div>
          //  desktop nav end
           :
          //  mobile navbar
           <div className='nav'>
           <Link className='underlinenone' to='/'>
           <div className='nav-logo-div'>
                  <img src={Logo} className='nav-logo'></img>
                  <img src={LogoText} className='nav-logo-text'></img>
           </div>
           </Link>
            <img onClick={() => setMobileNav(!MobileNav)} className='mobilenavmenu' src={mobilenavmenu}></img>
            <div className={ MobileNav ? 'mobile-nav-show' : 'none' }>
                 {
                   navdata.map(item => {
                    if(item.name == 'მთავარი'){
                     return ( <div className='mobile-nav-topic-div'>
                              <div onClick={() => setDropdownmb(!dropdownmb)} className='mobile-nav-topic-flex'>
                                <span>+ </span>
                                <NavLink className='hover' key={item.id} to={item.path}>{item.name}</NavLink>
                              </div>
                              <div className={ dropdownmb ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                              {
                                  item.sublist.map(subitem => {
                                    return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={subitem.path}>{subitem.name}</NavLink>
                                  })
                                }
                              </div>
                              </div>)
                    }
                    else if(item.name == 'წევრები'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb1(!dropdownmb1)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink className='hover' key={item.id} to={item.path}>{item.name}</NavLink>
                               </div>
                               <div className={ dropdownmb1 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={subitem.path}>{subitem.name}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                     else if(item.name == 'ღია ბანკინგი'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div  className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <a key={item.id} target='_blank' href="https://openfinance.ge/">{item.name}</a>
                               </div>
                               </div>)
                     }
                     else if(item.name == 'ჩვენს შესახებ'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb2(!dropdownmb2)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink className='hover' key={item.id} to={item.path}>{item.name}</NavLink>
                               </div>
                               <div className={ dropdownmb2 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={subitem.path}>{subitem.name}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                     else if(item.name == 'კონტაქტი'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink onClick={() => setMobileNav(false)} key={item.id} to={item.path}>{item.name}</NavLink>
                               </div>
                               </div>
                               )
                     }
                     else{
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb3(!dropdownmb3)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink key={item.id} to={item.path}>{item.name}</NavLink>
                               </div>
                               <div className={ dropdownmb3 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={subitem.path}>{subitem.name}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                   })
                   
                 }
                 <div className='mobile-nav-hr'></div>
                 <div className='mobile-search-lang-div'>
                       <input onKeyUp={e => {setSearchValue(e.target.value);if(e.key === 'Enter'){ window.location.assign('/search?keyword='+inpValue)}}} className='search-inp'></input>
                       <div className='nav-lang'> 
                        <img onClick={() => window.location.assign('/search?keyword='+inpValue)} className='search-icon' src={Searchicon}></img>
                        <div className='lang-hr'></div>
                        <a 
                        // onClick={() => langfun(!lang) } 
                        href={
                          loc?.includes('/en') ? 
                          loc?.split('/en')[1] 
                             :
                          '/en'+loc
                        }
                        className='btn-lang'>{ !lang ? 'ქარ' : 'ENG'}</a>
                      </div>
                 </div>
            </div>
          </div>
          //mobile nav end
          )
          :
          ( deviceType() ? 
          <div className='nav'>
            <Link className='underlinenone' to='/en/'>
            <div className='nav-logo-div'>
                   <img src={Logo} className='nav-logo'></img>
                   <img src={LogoTextEng} className='nav-logo-text'></img>
            </div>
            </Link>
            <ul className='nav-links'>
                {navdata.map(item => {
                  if(item.name == 'წევრები'){
                    return ( 
                      <li onClick={() => {setDropdown1(!dropdown1);setDropdown2(false);setDropdown3(false);setDropdown4(false);setDropdown(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <span className={dropdown1 ? 'hover link_active fontNoto' : 'hover fontNoto'}  to={item.path}>{item.name_eng}</span>
                           {dropdown1 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                  else if(item.name == 'ღია ბანკინგი'){
                    return ( 
                      <li 
                           key={item.id} className={item.cName}>
                           <a className='fontNoto' target='_blank' href='https://openfinance.ge/en'>{item.name_eng}</a>
                      </li>
                     )

                  }
                  else if(item.name == 'ჩვენს შესახებ'){
                    return ( 
                      <li onClick={() => {setDropdown3(!dropdown3);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown(false);setSearchDrop(false);}}
                            key={item.id} className={item.cName}>
                           <span className={dropdown3 ? 'hover link_active fontNoto' : 'hover fontNoto'}  to={item.path}>{item.name_eng}</span>
                           {dropdown3 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                  else if(item.name == 'კონტაქტი'){
                    return ( 
                      <li onClick={() => {setDropdown(false);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <NavLink className='fontNoto'  to={`/en${item.path}`}>{item.name_eng}</NavLink>
                           {dropdown4 && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                    
                  }
                  else{
                    return ( 
                      <li onClick={() => {setDropdown(!dropdown);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);setSearchDrop(false);}}
                           key={item.id} className={item.cName}>
                           <span className={dropdown ? 'hover link_active fontNoto' : 'hover fontNoto'}  to={item.path}>{item.name_eng}</span>
                           {dropdown && <Dropdown sub={item.sublist} lang={lang} />}
                      </li>
                     )
                  }
                })}
            </ul>
            <div className='nav-lang'> 
              <img onClick={() => {setSearchDrop(!SearchDrop);setDropdown(false);setDropdown1(false);setDropdown2(false);setDropdown4(false);setDropdown3(false);}} className='search-icon' src={Searchicon}></img>
              <div className={ SearchDrop ? 'hover-nav' : 'none'}>
                 <input onKeyUp={e => {setSearchValue(e.target.value);if(e.key === 'Enter'){ window.location.assign('/search?keyword='+inpValue)}}} className='search-inp'></input>
                 { lang ?
                  <button onClick={() => window.location.assign('/search?keyword='+inpValue)} className='btn-search'>ძიება</button>
                  :
                  <button onClick={() => window.location.assign('/search?keyword='+inpValue)} className='btn-search fontNoto'>Search</button>
                 }
              </div>
              <div className='lang-hr'></div>
              <a 
              // onClick={() => langfun(!lang) } 
                href={
                  loc?.includes('/en') ? 
                  loc?.split('/en')[1] 
                    :
                  '/en'+loc
                }
              className='btn-lang'>{ !lang ? 'ქარ' : 'ENG'}</a>
            </div>
           </div>
          //  desktop nav end
           :
          //  mobile navbar
           <div className='nav'>
           <Link className='underlinenone' to='/en/'>
           <div className='nav-logo-div'>
                  <img src={Logo} className='nav-logo'></img>
                  <img src={LogoTextEng} className='nav-logo-text'></img>
           </div>
           </Link>
            <img onClick={() => setMobileNav(!MobileNav)} className='mobilenavmenu' src={mobilenavmenu}></img>
            <div className={ MobileNav ? 'mobile-nav-show' : 'none' }>
                 {
                   navdata.map(item => {
                    if(item.name == 'მთავარი'){
                     return ( <div className='mobile-nav-topic-div'>
                              <div onClick={() => setDropdownmb(!dropdownmb)} className='mobile-nav-topic-flex'>
                                <span>+ </span>
                                <NavLink className='hover' key={item.id} to={`/en${item.path}`}>{item.name_eng}</NavLink>
                              </div>
                              <div className={ dropdownmb ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                              {
                                  item.sublist.map(subitem => {
                                    return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={subitem.path}>{subitem.name_eng}</NavLink>
                                  })
                                }
                              </div>
                              </div>)
                    }
                    else if(item.name == 'წევრები'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb1(!dropdownmb1)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink className='hover' key={item.id} to={`/en${item.path}`}>{item.name_eng}</NavLink>
                               </div>
                               <div className={ dropdownmb1 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={`/en${subitem.path}`}>{subitem.name_eng}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                     else if(item.name == 'ღია ბანკინგი'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div  className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <a key={item.id} target='_blank' href="https://openfinance.ge/en">{item.name_eng}</a>
                               </div>
                               </div>)
                     }
                     else if(item.name == 'ჩვენს შესახებ'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb2(!dropdownmb2)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink className='hover' key={item.id} to={`/en${item.path}`}>{item.name_eng}</NavLink>
                               </div>
                               <div className={ dropdownmb2 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={`/en${subitem.path}`}>{subitem.name_eng}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                     else if(item.name == 'კონტაქტი'){
                      return ( <div className='mobile-nav-topic-div'>
                               <div className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink onClick={() => setMobileNav(false)} key={item.id} to={`/en${item.path}`}>{item.name_eng}</NavLink>
                               </div>
                               </div>
                               )
                     }
                     else{
                      return ( <div className='mobile-nav-topic-div'>
                               <div onClick={() => setDropdownmb3(!dropdownmb3)} className='mobile-nav-topic-flex'>
                                 <span>+ </span>
                                 <NavLink key={item.id} to={`/en${item.path}`}>{item.name_eng}</NavLink>
                               </div>
                               <div className={ dropdownmb3 ? 'mobile-nav-topic-div-sublinks' : 'none'}>
                               {
                                   item.sublist.map(subitem => {
                                     return <NavLink onClick={() => setMobileNav(false)} key={subitem.id} className='' to={`/en${subitem.path}`}>{subitem.name_eng}</NavLink>
                                   })
                                 }
                               </div>
                               </div>)
                     }
                   })
                   
                 }
                 <div className='mobile-nav-hr'></div>
                 <div className='mobile-search-lang-div'>
                       <input onKeyUp={e => {setSearchValue(e.target.value);if(e.key === 'Enter'){ window.location.assign('/search?keyword='+inpValue)}}} className='search-inp'></input>
                       <div className='nav-lang'> 
                        <img onClick={() => window.location.assign('/search?keyword='+inpValue)} className='search-icon' src={Searchicon}></img>
                        <div className='lang-hr'></div>
                        <a 
                        // onClick={() => langfun(!lang) } 
                          href={
                            loc?.includes('/en') ? 
                            loc?.split('/en')[1] 
                              :
                            '/en'+loc
                          }
                          className='btn-lang'>{ !lang ? 'ქარ' : 'ENG'}</a>
                      </div>
                 </div>
            </div>
          </div>
          //mobile nav end
          )
          }
           </>
  )
  //
}

