export const navdata = 
    [
      {   id:1,
          name:"მთავარი",
          name_eng:"HOME",
          path:'/',
          cName:'Nav-item',
          sublist:[
              { subid:1,
                name:'სიახლეები',
                name_eng:"NEWS",
                path:'/news'
              }, 
              { subid:2,
                name:'ანალიტიკა',
                name_eng:"ANALYTICS",
                path:'/analytics'
              },
              { subid:3,
                name:'ღონისძიებები',
                name_eng:"EVENTS",
                path:'/events'
              },
              { subid:4,
                name:'პროექტები',
                name_eng:"PROJECTS",
                path:'/projects'
              }
            ]
      },
      {   id:2,
          name:"წევრები",
          name_eng:"MEMBERS",
          path:'/members',
          cName:'Nav-item',
          sublist:[
            { subid:1,
              name:'ბანკები',
              name_eng:"BANKS",
              path:'/members/banks'
            }, 
            { subid:2,
              name:'ფინანსური მონაცემები',
              name_eng:"FINANCIAL DATA",
              path:'/members/financial-data'
            }
          ]
      },
      {   id:3,
          name:"ღია ბანკინგი",
          name_eng:"OPEN BANKING",
          path:'/openbanking',
          cName:'Nav-item',
          sublist:[
            { subid:1,
              name:'არვიცი',
              path:'/openbanking'
            }
          ]
      }
      ,
      {   id:4,
          name:"ჩვენს შესახებ",
          name_eng:"ABOUT US",
          path:'/about-us',
          cName:'Nav-item',
          sublist:[
            { subid:1,
              name:'მმართველობა',
              name_eng:"GOVERNANCE",
              path:'/about-us/governance'
            }, 
            { subid:2,
              name:'მისია',
              name_eng:"MISSION",
              path:'/about-us/mission'
            },
            { subid:4,
              name:'კომიტეტები',
              name_eng:"COMMITTEES",
              path:'/about-us/committee'
            }
          ]
      }
      ,
      {   id:5,
          name:"კონტაქტი",
          name_eng:"CONTACT",
          path:'/contact',
          cName:'Nav-item',
          sublist:[
           
          ]
      }
    ]