import {useState,useEffect} from 'react';
import Teamtop from '../img/teamtop.png';
import './Banks.css';

import Arrowright from '../img/arrowright.svg';
import { Helmet } from 'react-helmet-async';

export default function Team({lang}) {
    const [Data, setData] = useState([])
    const [ChiefData, setChiefData] = useState([])
    const [TeamData, setTeamData] = useState([])


    let getData = async () => {
        let response = await fetch('/api/about/governance/')
        let data = await response.json()
        
        setData(data.governance)
        setChiefData(data.chiefs)
        setTeamData(data.team)
    }

    useEffect(() => {
        getData()   
    },[])

    useEffect(() => {
        getData()   
    },[lang])

    
    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
           return false;
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
           return false;
        }
        return true;
     };

    //მმართველების Card კომპონენტი
    function MemberCard({Member}){
        return(
            <>
            { !lang ?
                <div className='member-card'>
                    <img src={Member.picture}></img>
                    <h3>{Member.full_name_en}</h3>
                    <span>{Member.job_title_en}</span>
                    {/* <p dangerouslySetInnerHTML={{ __html: Member.description_en }} ></p> */}
                </div>
                :
                <div className='member-card'>
                    <img src={Member.picture}></img>
                    <h3>{Member.full_name}</h3>
                    <span>{Member.job_title}</span>
                    {/* <p dangerouslySetInnerHTML={{ __html: Member.description }} ></p> */}
                </div>
            }
            </>
           
        )
    }

    //მმართველების Card-ების დამაპვა
    const Members = ({Teamdata}) => Teamdata.map((member,index) => {
        return <MemberCardNew key={index} person={member} />
    })

    //ბოლოში გუნდის წევრების კომპონენტი
    function TeamList({Member}){
        return(
            <>
            { lang ? 
                <div className='Team-list-member'>
                  <h3 className='other-news-home'>{Member.full_name}</h3>
                  <h4 className=''>{Member.job_title}</h4>
                </div> 
                :
                <div className='Team-list-member'>
                  <h3 className='other-news-home'>{Member.full_name_en}</h3>
                  <h4 className=''>{Member.job_title_en}</h4>
                </div>
            }
            </>
        )
    }

     //ბოლოში გუნდის წევრების დამაპვა 
    const TeamMembers = ({Teamdata}) => Teamdata.map((member,index) => {
        return <MemberCardNew key={index} person={member} />
    })

    function MemberCardNew({person}){

        const [ShowBank,SetShowBank] = useState(false);
  
            return (
                <>

                { lang ?
                     <>
                     <div style={{marginBottom:'3vw',boxShadow:'none'}} className='bank-card ' onClick={() => SetShowBank(!ShowBank) }>
                         <img style={{objectFit:'cover'}} src={person.picture}></img>
                         <h2>{person.full_name}</h2>
                         <h4 className='new-member-h4'>{person.job_title}</h4>
                     </div>
                     <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                     className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                         <div className='bank-modal forp'>
                           <img style={{objectFit:'cover',marginTop:'1vw'}} src={person.picture}></img>
                           <h2 style={{marginBottom:'0'}}>{person.full_name}</h2>
                           <h4 style={{marginTop:'0'}} className='new-member-h4'>{person.job_title}</h4>
                             <span dangerouslySetInnerHTML={{ __html: person.description }} ></span>
                         </div>
                     </div>
                   </>
                   :
                    <>
                    <div style={{marginBottom:'3vw',boxShadow:'none'}} className='bank-card ' onClick={() => SetShowBank(!ShowBank) }>
                        <img style={{objectFit:'cover'}} src={person.picture}></img>
                        <h2 className='fontNoto'>{person.full_name_en}</h2>
                        <h4 className='fontNoto new-member-h4'>{person.job_title_en}</h4>
                    </div>
                    <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                    className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                        <div className='bank-modal forp'>
                          <img style={{objectFit:'cover',marginTop:'1vw'}} src={person.picture}></img>
                          <h2 style={{marginBottom:'0'}} className='fontNoto'>{person.full_name_en}</h2>
                          <h4 style={{marginTop:'0'}} className='new-member-h4 fontNoto'>{person.job_title_en}</h4>
                          <span dangerouslySetInnerHTML={{ __html: person.description_en }} ></span>
                        </div>
                    </div>
                  </>
                }
                </>
            )
      }

  if( Data.length == 0){
    return <div style={{height:'50vh',width:'99vw'}}>
             
         </div>
   }  
 
    return (

        <>
        {
                lang ? 
                <Helmet>
                <title>მმართველობა - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true" />
                <meta property="og:title" content="მმართველობა - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/about-us/governance" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="მმართველობა - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/about-us/governance" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Governance -Banking Association of Georgia</title>
                <meta name="description" content="Governance of the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Governance -Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Governance of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/about-us/governance" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Governance -Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Governance of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/about-us/governance" data-react-helmet="true"/>
              </Helmet>
              }
        { lang ? 
                  ( deviceType() ? 
                //desktop
                  <>
                  <div className='flex-start margintopstart'>
                    <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მმართველობა </span> 
                    </div>
                    <div className='home-hr'></div>
                    <div className='team-top'>
                        <div className='team-top-left'>
                            <label>მმართველობა</label>
                            <p>{Data.header}</p> 
                        </div>
                        <img alt='man-writing' src={Data.picture}></img>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
                        <div className='flex-start'>
                             <span className='other-news-home margintop1p5'>გუნდი</span> 
                        </div>
                        <div className='home-hr'> </div>
                    <div style={{marginBottom:'0'}} className='team-bottom'>
                            <Members Teamdata={ChiefData} />
                    </div> 
                        <div style={{marginTop:'0'}} className='team-bottom'>
                                <TeamMembers Teamdata={TeamData} />
                        </div>
                    </>
                  :
                  //mobile
                  <>
                    <div className='flex-start margintopstart'>
                        <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მმართველობა </span> 
                        </div>
                        <div className='home-hr'></div>
                    <div className='team-top'>
                            <label>მმართველობა</label>
                            <img alt='man-writing' src={Data.picture}></img>
                            <p className='team-top-leftp'>{Data.header}</p> 
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
                         <div className='flex-start'>
                             <span className='other-news-home margintop1p5'>გუნდი</span> 
                        </div>
                    <div className='home-hr'></div>
                    <div style={{marginBottom:'0'}} className='team-bottom'>
                            <Members Teamdata={ChiefData} />
                    </div>
                        <div style={{marginTop:'0'}} className='team-bottom'>
                                <TeamMembers Teamdata={TeamData} />
                        </div>
                    </>
                    )
                    :
                    ( deviceType() ? 
                //desktop
                <>
                <div className='flex-start margintopstart'>
                    <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Governance </span> 
                    </div>
                    <div className='home-hr'></div>
                    <div className='team-top'>
                        <div className='team-top-left'>
                            <label>Governance</label>
                            <p>{Data.header_en}</p> 
                        </div>
                        <img alt='man-writing' src={Data.picture}></img>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
                      <div className='flex-start'>
                             <span className='other-news-home margintop1p5'>Team</span> 
                        </div>
                    <div className='home-hr'></div>
                    <div style={{marginBottom:'0'}} className='team-bottom'>
                            <Members Teamdata={ChiefData} />
                    </div>
                        <div style={{marginTop:'0'}} className='team-bottom'>
                                <TeamMembers Teamdata={TeamData} />
                        </div>
                    </>
                :
                //mobile
                <>
                    <div className='flex-start margintopstart'>
                        <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Governance </span> 
                        </div>
                        <div className='home-hr'></div>
                    <div className='team-top'>
                            <label>Governance</label>
                            <img alt='man-writing' src={Data.picture}></img>
                            <p className='team-top-leftp'>{Data.header_en}</p> 
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
                          <div className='flex-start'>
                             <span className='other-news-home margintop1p5'>Team</span> 
                        </div>
                    <div className='home-hr'></div>
                    <div style={{marginBottom:'0'}} className='team-bottom'>
                            <Members Teamdata={ChiefData} />
                    </div>
                        <div style={{marginTop:'0'}} className='team-bottom'>
                                <TeamMembers Teamdata={TeamData} />
                        </div>
                    </>
                    )

                  }
      
        </>
      )
  }
  

