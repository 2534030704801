export default
{
    "carousel": [
        {
            "id": 3,
            "header": "<div>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი</div>",
            "title": "ფაქტორინგის განვითარების მნიშვნელობა",
            "date": "May. 12, 2022",
            "category": "კვლევები",
            "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph3.png"
        },
        {
            "id": 2,
            "header": "<p>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი&nbsp;</p>",
            "title": "ქართული საბანკო სექტორი უკრაინის მხარდასაჭერად",
            "date": "May. 12, 2022",
            "category": "სიახლეები",
            "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph2.png"
        },
        {
            "id": 1,
            "header": "<p>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი</p>",
            "title": "ქართული საბანკო სექტორი უკრაინის მხარდასაჭერად",
            "date": "May. 12, 2022",
            "category": "სიახლეები",
            "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph4.png"
        }
    ],
    "categories": [
        {
            "name": "სიახლეები",
            "articles": [
                {
                    "id": 4,
                    "header": "<p>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი</p>",
                    "title": "VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი ბანკებისა და საფინანსო ინსტიტუტების მომსახურება",
                    "date": "May. 03, 2022",
                    "category": "სიახლეები",
                    "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph3_roxTmZ3.png"
                },
                {
                    "id": 2,
                    "header": "<p>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი&nbsp;</p>",
                    "title": "ქართული საბანკო სექტორი უკრაინის მხარდასაჭერად",
                    "date": "May. 12, 2022",
                    "category": "სიახლეები",
                    "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph2.png"
                },
                {
                    "id": 1,
                    "header": "<p>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი</p>",
                    "title": "ქართული საბანკო სექტორი უკრაინის მხარდასაჭერად",
                    "date": "May. 12, 2022",
                    "category": "სიახლეები",
                    "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph4.png"
                }
            ]
        },
        {
            "name": "კვლევები",
            "articles": [
                {
                    "id": 3,
                    "header": "<div>გაცნობებთ, რომ საერთაშორისო თანამეგობრობის მიერ რუსეთისთვის დაწესებული სანქციების ფარგლებში, დღეს VISA და Mastercard-მა შეწყვიტეს რუსეთის ფედერაციაში მოქმედი</div>",
                    "title": "ფაქტორინგის განვითარების მნიშვნელობა",
                    "date": "May. 12, 2022",
                    "category": "კვლევები",
                    "photo": "https://cdn.test.bankingassociation.ge/articles/pictures/ph3.png"
                }
            ]
        }
    ]
}
    