import React from 'react';
import './OpenBank.css';
import redpdf from '../img/redpdf.svg';
import word from '../img/word.svg';
import web from '../img/web.svg';

export default function Standarts({lang}) {

  return (

    <>
    <div className='flex-start margintopstart'>
           <h1 className='open-standart-h1'>საქართველოს ღია ბანკინგის სტანდარტი</h1>
    </div>
    <div className='flex-start'>
           <p style={{width:'27vw',marginLeft:'0.5vw'}} className='font-mkhedr'>საქართველოს ღია ბანკინგის სტანდარტის მოქმედი ვერსია (0.6) შეგიძლიათ ჩამოტვირთოთ PDF და Microsoft Word ფორმატში. ღია ბანკინგის სტანდარტის წინა ვერსიები ამჟამად ხელმისაწვდომი არ არის გთხოვთ, თქვენი შენიშვნები და წინადადებები ღია ბანკინგის სტანდარტთან დაკავშირებით გამოგვიგზავნოთ საქართველოს საბანკო ასოციაციაში, ელფოსტის მისამართზე <a className='mail' href="mailto:obstandards@association.ge">obstandards@association.ge</a></p>
    </div>
    <div className='home-hr'></div>
    <div className='text-pdf-flex'>
        <div className='text-pdf-col'>
              <span className='text-pdf-header'>NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელო ვერსია 0.8.RC2</span>
              <span className='text-pdf-date'>06 მარტი, 2022</span>
              <p className='text-pdf-p'>ეს დოკუმენტი წარმოადგენს NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელოს, საქართველოს კანონმდებლობასთან თავსებადი ღია ბანკინგის დანერგვის მიზნით. დოკუმენტი სრულად თავსებადია შესაბამის ჩარჩოსთან (1) და (2)_თან, აზუსტებს მათ მოთხოვნებს, საქართველოს კანონმდებლობისა და ადგილობრივი კონტექსტის გათვალისწინებით.</p>
        </div>
         <div className='pdf-content-flex'>
                 <img src={redpdf}></img>
                 <img src={word}></img>
                 <img src={web}></img>
         </div>
    </div>
    <div className='text-pdf-flex'>
        <div className='text-pdf-col'>
              <span className='text-pdf-header'>NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელო ვერსია 0.8.RC2</span>
              <span className='text-pdf-date'>06 მარტი, 2022</span>
              <p className='text-pdf-p'>ეს დოკუმენტი წარმოადგენს NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელოს, საქართველოს კანონმდებლობასთან თავსებადი ღია ბანკინგის დანერგვის მიზნით. დოკუმენტი სრულად თავსებადია შესაბამის ჩარჩოსთან (1) და (2)_თან, აზუსტებს მათ მოთხოვნებს, საქართველოს კანონმდებლობისა და ადგილობრივი კონტექსტის გათვალისწინებით.</p>
        </div>
         <div className='pdf-content-flex'>
                 <img src={redpdf}></img>
                 <img src={word}></img>
                 <img src={web}></img>
         </div>
    </div>
    <div className='text-pdf-flex'>
        <div className='text-pdf-col'>
              <span className='text-pdf-header'>NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელო ვერსია 0.8.RC2</span>
              <span className='text-pdf-date'>06 მარტი, 2022</span>
              <p className='text-pdf-p'>ეს დოკუმენტი წარმოადგენს NextGenPSD2 XS2A ჩარჩოს განხორციელების სახელმძღვანელოს, საქართველოს კანონმდებლობასთან თავსებადი ღია ბანკინგის დანერგვის მიზნით. დოკუმენტი სრულად თავსებადია შესაბამის ჩარჩოსთან (1) და (2)_თან, აზუსტებს მათ მოთხოვნებს, საქართველოს კანონმდებლობისა და ადგილობრივი კონტექსტის გათვალისწინებით.</p>
        </div>
         <div className='pdf-content-flex'>
                 <img src={redpdf}></img>
                 <img src={word}></img>
                 <img src={web}></img>
         </div>
    </div>
    </>
  )
}
