import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Home.css';

export default function ({Card,lang}) {
  return (
        <>
           { lang ?
                 <div className='homecard-div'>
                    <Link className="underlinenone" to={`/projects/${Card.url_title}`}> 
                    <img src={Card.photo}></img>
                    </Link>
                    <Link className="underlinenone" to={`/projects/${Card.url_title}`}> 
                    <h2>{Card.title}</h2>
                    </Link>
                    <p>{Card.header}</p>
                 </div>
            :
                  <div className='homecard-div'>
                    <Link className="underlinenone" to={`/en/projects/${Card.url_title}`}> 
                    <img src={Card.photo}></img>
                    </Link>
                    <Link className="underlinenone" to={`/en/projects/${Card.url_title}`}> 
                    <h2>{Card.title_en}</h2>
                    </Link>
                    <p>{Card.header_en}</p>
                 </div>
 
           }
        </>
   
  )
}
